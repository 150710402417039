import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const sectionH3Images = {
  webhook: [
    {
      img: (
        <StaticImage
          src="https://res.cloudinary.com/cryptr/image/upload/v1657212308/Cryptr%20www/Landing%20pages/SCIM/webhook_r34ccd.jpg"
          alt="Webhook code"
          quality={50}
          width={1080}
          formats={['auto', 'webp', 'avif']}
          placeholder="none"
          className="feature"
        />
      ),
    },
  ],
}
