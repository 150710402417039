import styled from '@emotion/styled'
import tw from 'twin.macro'

export const ImageWrapper = styled.div`
  ${tw`
    w-1/2
    relative
    flex
    items-center
  `}
`
