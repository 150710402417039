import React from 'react'
import { partnerLogos } from '../../cdn_images/logos'
import Hero from '../../components/hero'
import { UserAccountHeroBackground } from '../../components/hero/hero_background'
import { HeroIllustration } from '../../components/images/scim/hero'
import Layout from '../../components/layout'
import SectionWrapper from '../../components/sectionWrapper'
import SectionH2 from '../../components/section_h2'
import SectionH3Image from '../../components/section_h3_image'
import SectionLogosBase from '../../components/section_logos_base'
import { ScimUsersImage } from '../../components/images/scim/section_h3_image'
import SectionH3 from '../../components/sections_h3'
import SectionH2Base from '../../components/section_h2_base'
import { SectionH2Image1 } from '../../components/images/scim/section_h2'
import { sectionH3Images } from '../../components/images/scim/section_h3'
import SectionValuePropositions from '../../components/section_value_propositions'
import CallToActionSection from '../../components/call_to_action_section'
import SvgScimIcon from '../../components/svgs/icons/products/scim_icon'

const ScimPage = ({ pageContext: { data, localePath } }: any) => {
  let featureForH3Image = [data.body[1].items[0]]
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <Hero
        background_shape={<UserAccountHeroBackground />}
        icon={<SvgScimIcon />}
        category={data.category}
        call_to_action_color={data.slug}
        pageID={data.slug}
        title={data.header_title.raw}
        description={data.header_description.raw}
        value_propositions={data.value_proposition}
        call_to_action={data.call_to_action}
      >
        <HeroIllustration />
      </Hero>
      <SectionWrapper color="dark-blue">
        <SectionLogosBase items={partnerLogos} />
      </SectionWrapper>

      <SectionWrapper color="light-blue" disabledPadding="bottom">
        <SectionH2
          slug={data.slug}
          title={data.body[0].section_H2_primary.title}
          description={data.body[0].section_H2_primary.description.raw}
          textAlignment="text-center"
          subhead={null}
          gridColumns={false}
        >
          <SectionH3Image
            slug={data.slug}
            features={featureForH3Image}
            image={<ScimUsersImage />}
            contentPosition="to-top"
            featuresOrder="to-left"
            imageOrder="to-right"
            icons={null}
          />
        </SectionH2>
      </SectionWrapper>
      {/* <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionH2Base identifier="developer" onlyWithChecks data={data.body[2]} slug={data.slug}>
          <SectionH2Image1 />
        </SectionH2Base>
      </SectionWrapper> */}
      <SectionWrapper disabledPadding="padding-auto">
        <SectionH3
          items={data.body[3].items}
          slug={data.slug}
          shadow={false}
          pictures={sectionH3Images.webhook}
        />
      </SectionWrapper>
      {/* <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionValuePropositions
          alignment="align-left"
          slug={data.slug}
          valuePropositions={data.body[4].value_propositions}
        />
      </SectionWrapper> */}
      {/* <CallToActionSection callToActionSectionData={data.body[5]} /> */}
    </Layout>
  )
}

export default ScimPage
