import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const ScimUsersImage = () => {
  return (
    <StaticImage
      src="https://res.cloudinary.com/cryptr/image/upload/v1657534731/Cryptr%20www/Landing%20pages/SCIM/scim_02_v0eevr.jpg"
      alt="Scim with users"
      quality={50}
      imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
      formats={['auto', 'webp', 'avif']}
      placeholder="none"
      className="image-to-top"
    />
  )
}
