import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { ImageWrapper } from './style'

export const HeroIllustration = () => {
  return (
    <ImageWrapper>
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1657209127/Cryptr%20www/Landing%20pages/SCIM/scim_cc1soa.png"
        alt="SSO Admin Onboarding illustration"
        imgStyle={{
          borderRadius: '0.5rem',
        }}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
      />
    </ImageWrapper>
  )
}
